
import { defineComponent, ref, onMounted, computed, watch, Ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import cmarks from "./cmarks.vue";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import { Check } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { event } from "vue-gtag";

interface NewAddressData {
  matiere: string;
  trimestre: string;
}
interface IStudent {
  _id: string;
  BirthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
}
interface ISubject {
  _id: string;
  name: string;
  status: string;
}

interface Mark {
  _id: string;
  type: string;
  mark: string;
  schoolarYear: string;
  student: string;
  subject: string;
  trimester: number;
}

export default defineComponent({
  name: "Marks",
  components: {
    cmarks,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const filter = ref<string>("");
    const list = ref<IStudent[]>([]);
    const listSubject = ref<ISubject[]>([]);
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");

    const sy = window.localStorage.getItem("activeSchoolarYear");
    const trimester = JwtService.getTrimester();
    const loading = ref(true);

    const targetData = ref<NewAddressData>({
      matiere: "",
      trimestre: trimester || "1",
    });

    const disabledAccess = ref(true);

    const currentMonth = new Date().getMonth();
    if (targetData.value.trimestre == "1") {
      if (currentMonth >= 8 && currentMonth <= 11) disabledAccess.value = false;
    } else if (targetData.value.trimestre == "2") {
      if (currentMonth >= 1 && currentMonth <= 3) disabledAccess.value = false;
    } else if (targetData.value.trimestre == "3") {
      if (currentMonth >= 3 && currentMonth <= 5) disabledAccess.value = false;
    }

    const rules = ref({
      matiere: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "change",
        },
      ],
      trimestre: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "change",
        },
      ],
    });
    const filtredStudents = computed(() => {
      if (filter.value) {
        return list.value.filter((s: IStudent) => {
          let name: string = s.firstName + " " + s.lastName;
          return name.includes(filter.value.toString());
        });
      }
      return list.value;
    });

    const marks = ref<Mark[]>([]);

    onMounted(async () => {
      //GET STUDENTS
      ApiService.setHeader();
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = route.params.id;
      await ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: { $arrayElemAt: ["$classRoom._id", 0] },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              teachers: {
                $arrayElemAt: ["$classRoom.teachers", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              firstNameLower: { $toLower: "$firstName" },
            },
          },
          {
            $sort: {
              firstNameLower: 1,
            },
          },
        ],
      }).then(({ data }) => {
        list.value = data;
      });

      ApiService.post("/mark/filter", {
        query: {
          schoolarYear: sy,
          trimester: targetData.value.trimestre || "1",
          classroom: route.params.id,
        },
      }).then(({ data }) => {
        data.forEach((el: Mark) => {
          if (el.type == "one") {
            marks.value.push(el);
          }
        });
        markRefs.value = [];
        loading.value = false;
      });

      //GET TEACHER
      ApiService.setHeader();
      ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: store.getters.currentUser._id,
        },
      })
        .then(({ data }) => {
          listSubject.value = [];
          const classroom = data.find(
            (el) => el.classRoom._id == route.params.id
          );
          if (classroom) {
            listSubject.value = classroom.subjects;

            classroom.modulesSpecific.map((m) => {
              listSubject.value.push(...m.subjects);
            });
            classroom.modules.map((m) => {
              listSubject.value.push(...m.subjects);
            });
          }

          //remove duplicated
          listSubject.value = listSubject.value.filter(
            (subject, index) =>
              listSubject.value.findIndex((s) => s._id == subject._id) ===
                index && subject.status !== "inactive"
          );
        })
        .catch((e) => console.log(e));
    });

    watch(
      targetData,
      () => {
        list.value = [];
        loading.value = true;

        const currentMonth = new Date().getMonth();

        disabledAccess.value = true;

        if (targetData.value.trimestre == "1") {
          if (currentMonth >= 8 && currentMonth <= 11)
            disabledAccess.value = false;
        } else if (targetData.value.trimestre == "2") {
          if (currentMonth >= 1 && currentMonth <= 3)
            disabledAccess.value = false;
        } else if (targetData.value.trimestre == "3") {
          if (currentMonth >= 3 && currentMonth <= 5)
            disabledAccess.value = false;
        }

        const match = {};
        match[`schoolarYearsHistory.${sy}`] = route.params.id;
        ApiService.post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: "$schoolarYearsHistory." + sy,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classRoom",
              },
            },
            {
              $project: {
                _id: 1,
                classRoom: {
                  $arrayElemAt: ["$classRoom._id", 0],
                },
                classRoomName: {
                  $arrayElemAt: ["$classRoom.name", 0],
                },
                teachers: {
                  $arrayElemAt: ["$classRoom.teachers", 0],
                },
                BirthDate: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                photo: 1,
                firstNameLower: { $toLower: "$firstName" },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        }).then(({ data }) => {
          list.value = data;
        });

        ApiService.post("/mark/filter", {
          query: {
            schoolarYear: sy,
            trimester: targetData.value.trimestre || "1",
            classroom: route.params.id,
          },
        }).then(({ data }) => {
          data.forEach((el: Mark) => {
            if (el.type == "one") {
              marks.value.push(el);
            }
          });
          markRefs.value = [];
          loading.value = false;
        });
      },
      { deep: true }
    );

    const getMark = (student: string, subject: string) => {
      const mark = marks.value.find((m: Mark) => {
        return (
          m.student == student &&
          m.subject == subject &&
          String(m.trimester) == String(targetData.value.trimestre)
        );
      });
      return mark || { mark: "" };
    };

    const markRefs = ref<
      Array<{
        newMarks: string;
        edits: boolean;
        marks: { _id: number; mark: string };
        addMark: (type: string, alert?: boolean) => void;
        updateMark: (id: number, type: string, alert?: boolean) => void;
      }>
    >([]);

    const addRef = (el) => {
      markRefs.value.push(el);
    };

    const saveAll = () => {
      let thereEmpty = false;
      let thereInvalid = false;
      markRefs.value.forEach((el) => {
        if (el.edits && !el.newMarks) {
          thereEmpty = true;
          return Swal.fire({
            text: t("notes.noEmpty"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        if (Number(el.newMarks) > 20 || Number(el.newMarks) < 0) {
          thereInvalid = true;
          return Swal.fire({
            text: t("notes.noInvalid"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });

      if (!thereEmpty && !thereInvalid) {
        markRefs.value.forEach((el) => {
          if (!el.marks.mark && el.newMarks) {
            el.addMark("one", false);
          }
          if (el.edits)
            if (el.newMarks) {
              el.updateMark(el.marks._id, "one", false);
            }
        });

        Swal.fire({
          text: t("notes.savedAll"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });

        event("Save all marks", {
          event_category: "Marks",
          event_label: "Classe profile",
          value: 1,
        });
      }
    };

    return {
      t,
      targetData,
      rules,
      list,
      onMounted,
      listSubject,
      filter,
      filtredStudents,
      apiUrl,
      getMark,
      Check,
      saveAll,
      addRef,
      loading,
      disabledAccess,
    };
  },
});
