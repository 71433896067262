
import { watch, defineComponent, toRefs, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { Edit, Check, Delete } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { createToaster } from "@meforma/vue-toaster";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { event } from "vue-gtag";

interface Mark {
  _id: string;
  type: string;
  mark: string;
  schoolarYear: string;
  student: string;
  subject: string;
  trimester: number;
}

export default defineComponent({
  name: "cmarks",
  components: {},
  props: {
    trimester: String,
    id: String,
    subject: String,
    mark: Object,
  },
  setup(props) {
    const { t } = useI18n();

    const { trimester, id, subject } = toRefs(props);

    const marks = ref<Partial<Mark>>(props.mark as Mark);
    console.log("marks:", marks.value);
    const newMarks = ref<string | null>(marks.value.mark as string);

    const edits = ref(false);

    const disabledAccess = ref(true);

    const currentMonth = new Date().getMonth();
    if (trimester.value == "1") {
      if (currentMonth >= 8 && currentMonth <= 11) disabledAccess.value = false;
    } else if (trimester.value == "2") {
      if (currentMonth >= 1 && currentMonth <= 3) disabledAccess.value = false;
    } else if (trimester.value == "3") {
      if (currentMonth >= 3 && currentMonth <= 5) disabledAccess.value = false;
    }

    const route = useRoute();

    ApiService.setHeader();

    watch(
      props,
      () => {
        marks.value = props?.mark as Mark;
      },
      { deep: true }
    );

    const addMark = (type, alert = true) => {
      if (disabledAccess.value) return;
      event("Add mark", {
        event_category: "Marks",
        event_label: "Classe profile",
        value: 1,
      });

      let newMark;

      if (type == "one") newMark = newMarks.value;
      if (newMark == null || newMark == "") {
        console.log("newMark:", newMark);

        if (alert)
          Swal.fire({
            text: t("notes.noEmpty"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        return;
      }
      if (newMark > 20 || newMark < 0) {
        if (alert)
          Swal.fire({
            text: t("notes.noInvalid"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        return;
      }
      ApiService.put("/mark/", {
        data: {
          subject: subject.value,
          student: id.value,
          mark: newMark,
          type: type,
          trimester: trimester.value,
          classroom: route.params.id,
        },
      }).then(({ data }) => {
        if (alert) {
          const toaster = createToaster({});
          toaster.success("Note ajoutée !", {
            position: "bottom-right",
          });
        }

        if (type == "one") marks.value = data;
      });
    };

    const updateMark = (id, type, alert = true) => {
      if (disabledAccess.value) return;
      event("Edit mark", {
        event_category: "Marks",
        event_label: "Classe profile",
        value: 1,
      });

      const newMark = newMarks.value;

      if (type == "one")
        if (newMark == null && alert) {
          Swal.fire({
            text: t("notes.noEmpty"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return;
        }

      if (Number(newMark) > 20 || Number(newMark) < 0) {
        if (alert)
          Swal.fire({
            text: t("notes.noInvalid"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        return;
      }

      ApiService.patch("/mark/" + id, {
        data: {
          mark: newMark,
        },
      }).then(() => {
        if (alert)
          Swal.fire({
            text: t("notes.updated"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });

        if (type == "one") {
          edits.value = false;
          marks.value.mark = newMark as string;
        }
      });
    };

    return {
      t,
      marks,
      newMarks,
      edits,
      Edit,
      addMark,
      updateMark,
      Check,
      Delete,
      disabledAccess,
    };
  },
});
